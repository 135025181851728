<template>
  <div class="text-container">
    <p>Beratung und Arbeitshilfen für das Qualitätsmanagement von Apotheken.</p>
    <p>Leistungen:</p>
    <ul>
      <li>
        Neueinführung eines Qualitätsmanagement-Systems nach DIN-EN ISO
        9001:2015
      </li>
      <li>
        Anpassen von Dokumenten und Handbuch "Ich mache die Schreibarbeit für
        Sie"
      </li>
      <li>
        QM-Check, Internes Audit, Unterstützung bei der Selbstinspektion durch
        unseren "hauseigenen" QM-Auditor aus dem pharmazeutischen Umfeld
      </li>
      <li>Interne Qualitätsmanagement-Schulungen</li>
      <li>
        Individuelle Lösungen für Apotheken mit einer oder mehreren Filialen
      </li>
      <li>Phamazeutisch und qualitätsbezogene Kompetenz aus einer Hand</li>
    </ul>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Beratung und Arbeitshilfe für das Qualitätsmanagement Ihrer Apotheke.",
      },
    ],
  },
};
</script>